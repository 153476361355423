import React, { useContext, useEffect, useState } from 'react';
import * as Styled from './OxPsychologicalAttributes.styled';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { psychologicalGetSurvey, psychologicalPostSurvey } from 'src/services/api/psychological';
import { EInputTheme, OxThemedInput } from 'src/components/OxThemedInput';
import { OxButton } from 'src/components/OxButton';
import { OxSpinner } from 'src/components/OxSpinner';
import { TPatient, TSurvey } from 'src/services/api/api.types';
import { AlertContext, EAlertVariant } from 'src/context/AlertContext';

export enum EPsychologicalAttributesType {
    personalFears = 'PERSONAL_FEARS',
    socialGoals = 'SOCIAL_GOALS',
    hairFears = 'HAIR_PERSONAL_FEARS',
    hairGoals = 'HAIR_SOCIAL_GOALS'
}

type TPsychologicalAttributes = {
    type: EPsychologicalAttributesType;
    patient: TPatient;
    handleLoadingChange: (value: boolean) => void;
    loading: boolean;
    onNext: () => void;
};

export const OxPsychologicalAttributes = ({
    onNext,
    patient,
    handleLoadingChange,
    loading,
    type
}: TPsychologicalAttributes): JSX.Element => {
    const [data, setData] = useState<TSurvey[] | null>(null);
    const { showAlert } = useContext(AlertContext);
    const [statementSet, setStatementSet] = useState<number | null>(null);

    const reorder = (list: any, startIndex: number, endIndex: number) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        const items = reorder(data, result.source.index, result.destination.index);

        setData(items);
    };

    const onNextClick = async (): Promise<void> => {
        if (!loading) {
            handleLoadingChange(true);
            const answers = data?.map((el, index) => {
                return {
                    statement_id: el.id,
                    statement_set: statementSet,
                    position: data.length - index
                };
            });

            try {
                const respondentId = await psychologicalPostSurvey(
                    {
                        surveyId:
                            type === EPsychologicalAttributesType.personalFears ||
                            type === EPsychologicalAttributesType.hairFears
                                ? 2
                                : 3
                    },
                    {
                        respondent: {
                            email: patient.email,
                            firstname: patient.firstName,
                            lastname: patient.lastName,
                            mobile:
                                patient.patientPhoneNumbers &&
                                patient.patientPhoneNumbers[0].number,
                            gender: patient.gender,
                            newsletter: patient.newsletter
                        },
                        answers: answers
                    }
                );
                if (respondentId) {
                    onNext();
                    setTimeout(() => {
                        handleLoadingChange(false);
                    }, 1000);
                } else {
                    showAlert({
                        type: EAlertVariant.Error,
                        header: 'Error',
                        title: 'Something went wrong'
                    });
                    handleLoadingChange(false);
                }
            } catch (err) {
                showAlert({
                    type: EAlertVariant.Error,
                    header: 'Error',
                    title: 'Something went wrong'
                });
                handleLoadingChange(false);
            }
        }
    };

    useEffect(() => {
        let unmounted = false;
        async function fetchData(): Promise<void> {
            const survey = await psychologicalGetSurvey();
            if (survey && !unmounted) {
                if (type === EPsychologicalAttributesType.personalFears) {
                    setStatementSet(survey[1].statementSets[0].id);
                    setData(survey[1].statementSets[0].statements);
                } else if (type === EPsychologicalAttributesType.socialGoals) {
                    setStatementSet(survey[2].statementSets[0].id);
                    setData(survey[2].statementSets[0].statements);
                } else if (type === EPsychologicalAttributesType.hairFears) {
                    setStatementSet(survey[1].statementSets[1].id);
                    setData(survey[1].statementSets[1].statements);
                } else if (type === EPsychologicalAttributesType.hairGoals) {
                    setStatementSet(survey[2].statementSets[1].id);
                    setData(survey[2].statementSets[1].statements);
                }
            }
        }

        fetchData();
        return (): void => {
            unmounted = true;
        };
    }, []);

    return (
        <>
            {data ? (
                <Styled.Container>
                    <Styled.Header>
                        {' '}
                        {(() => {
                            switch (type) {
                                case EPsychologicalAttributesType.personalFears:
                                    return 'Personal Fears';
                                case EPsychologicalAttributesType.socialGoals:
                                    return 'Social Goals';
                                case EPsychologicalAttributesType.hairFears:
                                    return 'Hair Growth Fears';
                                case EPsychologicalAttributesType.hairGoals:
                                    return 'Hair Growth Goals';
                            }
                        })()}
                    </Styled.Header>
                    <Styled.Text>
                        {`We would like you to evaluate the below statements. Please indicate to what extent these statements reflect
              your preferential behaviour by dragging them into order, ${
                  data.length
              } being the highest ${
                            type === EPsychologicalAttributesType.personalFears ||
                            type === EPsychologicalAttributesType.hairFears
                                ? 'fear'
                                : 'goal'
                        } and 1 being the lowest. Please touch(long press) and drag the items into your preferred order.`}
                    </Styled.Text>
                    <Styled.DragDropContainer>
                        <Styled.PointsContainer>
                            {data.map((point, index) => (
                                <Styled.PointContainer key={point.id}>
                                    <Styled.Point>{data.length - index}</Styled.Point>
                                </Styled.PointContainer>
                            ))}
                        </Styled.PointsContainer>
                        <DragDropContext onDragEnd={onDragEnd}>
                            <Droppable droppableId="droppable">
                                {(provided, snapshot) => (
                                    <Styled.DragDropContentContainer
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                        isDraggingOver={snapshot.isDraggingOver}
                                    >
                                        {data.map((el, index) => (
                                            <Draggable
                                                key={el.name}
                                                draggableId={el.name}
                                                index={index}
                                            >
                                                {(provided, snapshot) => (
                                                    <Styled.DraggableItem
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        isDragging={snapshot.isDragging}
                                                    >
                                                        {el.label}
                                                        <Styled.DragIcon name="dragIndicator" />
                                                    </Styled.DraggableItem>
                                                )}
                                            </Draggable>
                                        ))}
                                    </Styled.DragDropContentContainer>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </Styled.DragDropContainer>
                    <OxThemedInput theme={EInputTheme.BackgroundGold}>
                        <OxButton
                            icon
                            onClick={onNextClick}
                            debounceClick
                            debounceClickDeps={[data, onNext, patient, type]}
                        >
                            NEXT
                        </OxButton>
                    </OxThemedInput>
                </Styled.Container>
            ) : (
                <Styled.SpinnerContainer>
                    <OxSpinner />
                </Styled.SpinnerContainer>
            )}
        </>
    );
};
