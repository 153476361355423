import styled, { css } from 'styled-components';

export const Container = styled.div`
    position: relative;
`;

export const TextArea = styled.textarea(
    ({ theme }) => css`
        width: 100%;
        height: 15rem;
        background: ${theme.colors.basic.white};
        cursor: pointer;
        border: 1px solid ${theme.colors.basic.darkOatmeal};
    `
);
