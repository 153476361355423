import React, { ChangeEvent, MouseEvent, useCallback, useContext, useMemo, useRef } from 'react';
import { OxContainer } from 'src/components/OxContainer';
import * as Styled from 'src/components/OxBookingSimple/components/OxBookingSimpleDetails/OxBookingSimpleDetails.styled';
import { EInputTheme, OxThemedInput } from 'src/components/OxThemedInput';
import { EOxInputType, OxInput } from 'src/components/OxInput';
import { EBookingFlowAction } from 'src/components/OxBooking/OxBookingFlowReducer';
import { OxPhoneInput } from 'src/components/OxPhoneInput';
import { OxSelect } from 'src/components/OxSelect';
import { EModalId } from 'src/components/OxHygene';
import { OxButton } from 'src/components/OxButton';
import { LayoutContext } from 'src/context/LayoutContext';
import { BookingFlowContext, EBookingType } from 'src/context/BookingFlowContext';
import { fireEvent } from 'src/helpers/TagManager';
import { TStepProps } from 'src/components/OxBookingSimple/components/OxBookingSimpleDetails/OxBookingSimpleDetails';
import { checkCustomerAccount } from 'src/components/OxBookingSimple/services/shared';
import { AlertContext } from 'src/context/AlertContext';
import { submitForm } from 'src/utils/form';
import { OxRadioList, TOxRadioListItem } from 'src/components/OxRadioList';
import WhatsAppButtonWhiteSmall from 'src/images/footer/WhatsAppButtonWhiteSmall.svg';
import { LocationsData } from 'src/constants/locations';

export const OxBookingSimpleStep1: React.FC<TStepProps> = ({
    isVirtual,
    onNext,
    user,
    clinic,
    submitButtonProps
}: TStepProps): JSX.Element => {
    const layoutContext = useContext(LayoutContext);
    const { showAlert } = useContext(AlertContext);
    const { state, paymentDisabled, dispatch } = useContext(BookingFlowContext);

    const submitButtonRef = useRef<HTMLButtonElement>();

    const fireGaEvent = useCallback((e: Event | MouseEvent<HTMLInputElement>): void => {
        const target = e.target as HTMLInputElement;
        fireEvent({ event: target.name + 'InputClickInSimpleBooking' });
    }, []);

    const inputOnChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>, action: EBookingFlowAction): void => {
            const target = e.target as HTMLInputElement | undefined;

            target &&
                dispatch({
                    type: action,
                    payload: target.value
                });
        },
        [dispatch]
    );

    const checkAndNext = async (): Promise<void> => {
        if (!submitButtonRef.current) return;
        const target = submitButtonRef.current as HTMLButtonElement;
        const form = target.form;

        if (!form?.reportValidity()) return;

        dispatch({
            type: EBookingFlowAction.SetProcessing,
            payload: true
        });

        // form.dataset.skipProcessingCheck = "true";
        // submitForm(form);
        await checkCustomerAccount({
            state,
            dispatch,
            data: new FormData(form),
            callback: async ({ userData }) => {
                if (state.hasPaymentMethods || state.paymentDisabled) {
                    // console.log("setProcessing false in checkCustomerAccount callback");
                    // dispatch({
                    //   type: EBookingFlowAction.SetProcessing,
                    //   payload: false,
                    // });
                    form.dataset.skipProcessingCheck = 'true';
                    submitForm(form, { userData });
                } else {
                    fireEvent({ event: `${state.type}NextClickedInDetails` });
                    onNext && onNext();
                }
            }
        }).catch((e) => {
            showAlert(e.error);
            dispatch({
                type: EBookingFlowAction.SetProcessing,
                payload: false
            });
        });

        // console.log("setProcessing false in checkAndNext");
        // dispatch({
        //   type: EBookingFlowAction.SetProcessing,
        //   payload: false,
        // });
    };

    const contactMethods: TOxRadioListItem[] = useMemo(() => {
        const items = [
            {
                name: 'Email',
                value: 'email'
            },
            {
                name: 'Phone',
                value: 'phone'
            },
            {
                name: 'SMS',
                value: 'sms'
            },
            {
                name: 'WhatsApp',
                value: 'whatsapp'
            }
        ];

        return items;
    }, [state.clinic?.id]);

    return (
        <OxContainer>
            <Styled.DetailsHeading>Your Details</Styled.DetailsHeading>
            <OxThemedInput theme={EInputTheme.GoldTransparent}>
                <Styled.Wrapper>
                    <OxInput
                        type={EOxInputType.Text}
                        name="firstname"
                        value={state.client?.firstName ?? user?.firstname}
                        onClick={fireGaEvent}
                        onChange={(e: ChangeEvent<HTMLInputElement>): void =>
                            inputOnChange(e, EBookingFlowAction.SetFirstName)
                        }
                        placeholder="First Name*"
                        required
                    />
                    <OxInput
                        type={EOxInputType.Text}
                        name="lastname"
                        value={state.client?.secondName ?? user?.lastname}
                        onClick={fireGaEvent}
                        onChange={(e: ChangeEvent<HTMLInputElement>): void =>
                            inputOnChange(e, EBookingFlowAction.SetSecondName)
                        }
                        placeholder="Last Name*"
                        required
                    />
                    <OxPhoneInput
                        name="mobile"
                        value={state.client?.mobile ?? user?.mobile}
                        onClick={fireGaEvent}
                        onChange={(e: ChangeEvent<HTMLInputElement>): void =>
                            inputOnChange(e, EBookingFlowAction.SetMobile)
                        }
                        initialCountryCode={state.clinic?.countryCode}
                        placeholder="Telephone"
                        required
                    />
                    <OxInput
                        type={state.client?.isLoggedIn ? EOxInputType.Hidden : EOxInputType.Email}
                        name="email"
                        value={user?.email ?? state.client?.email}
                        onClick={fireGaEvent}
                        onChange={(e: ChangeEvent<HTMLInputElement>): void =>
                            inputOnChange(e, EBookingFlowAction.SetEmail)
                        }
                        placeholder="Email Address*"
                        required
                    />
                    {!isVirtual && (
                        <OxRadioList
                            name="contactMethod"
                            label="Please select your preferred method of contact:"
                            radioShape="roundedSquare"
                            // onValueChange={fireGaEvent}
                            items={contactMethods}
                            required
                        />
                    )}
                    {!!isVirtual && (
                        <OxSelect
                            name="consultationtype"
                            title="Conversation Type"
                            labelName="label"
                            valueName="value"
                            onClick={fireGaEvent}
                            items={[
                                {
                                    label: 'Phone Conversation',
                                    value: 'Phone Consultation'
                                },
                                {
                                    label: 'Video Conversation',
                                    value: 'Video Consultation'
                                }
                            ]}
                            required
                        />
                    )}
                    <Styled.Checkbox
                        label="* I agree to the full"
                        labelLinkTitle="terms and conditions"
                        onClick={fireGaEvent}
                        labelLinkOnClick={(): void => {
                            layoutContext.setModalId(EModalId.Terms);
                        }}
                        id="terms_checkbox"
                        name="terms"
                        value="terms"
                        shape="roundedSquare"
                        required
                    />
                    <Styled.Checkbox
                        label="Receive Ouronyx newsletter"
                        id="newsletter_checkbox"
                        onClick={fireGaEvent}
                        name="newsletter"
                        value="newsletter"
                        shape="roundedSquare"
                        initialChecked
                    />
                    <OxButton
                        {...submitButtonProps}
                        ref={submitButtonRef}
                        type="button"
                        onClick={checkAndNext}
                        id="bookingStep1Submit"
                        disabled={state.processing || submitButtonProps.disabled}
                        loading={!!(state.processing || submitButtonProps.loading)}
                        debounceClick
                        debounceClickDeps={[clinic, state, submitButtonRef]}
                        icon
                        style={{ width: '100%' }}
                    >
                        {state.hasPaymentMethods ||
                        state.type === EBookingType.Virtual ||
                        paymentDisabled
                            ? 'Submit'
                            : 'Next'}
                    </OxButton>
                    <Styled.Note>
                        Our Client Advisor Team will be in contact with you to finalise your
                        booking.
                    </Styled.Note>
                    <Styled.WhatsAppLink
                        withUnderlineAnimation
                        target="_blank"
                        to={
                            'https://wa.me/' +
                            LocationsData[state.clinic?.label?.toLocaleLowerCase() ?? 'london']
                                .whatsapp
                        }
                    >
                        <img alt="Chat on WhatsApp" src={WhatsAppButtonWhiteSmall} />
                    </Styled.WhatsAppLink>
                </Styled.Wrapper>
            </OxThemedInput>
        </OxContainer>
    );
};
