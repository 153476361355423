import { EFontWeight, EFonts } from 'src/config/enums';
import { moveLeftBack } from 'src/styles/animations';
import styled, { css } from 'styled-components';

import { OxForm } from '../OxForm';
import { createTextStyle } from 'src/utils/createTextStyle';
import { fluidSizing } from 'src/utils';
import { OxIcon } from 'src/components/OxIcon';

export const Container = styled.div(
    ({ theme }) => css`
        grid-area: FooterSubscribe;
        display: flex;
        align-items: flex-start;
        justify-content: end;

        ${theme.breakpoints.up('s')} {
            ${fluidSizing([{ prop: 'margin-bottom', values: [15, 15, 15] }])}
        }

        span {
            text-transform: uppercase;
            ${createTextStyle(EFonts.Montserrat, EFontWeight.SemiBold, [10, 10, 10], [10, 10, 10])}
            ${fluidSizing([{ prop: 'margin-bottom', values: [10, 10, 10] }])};
        }
        h4 {
            ${createTextStyle(EFonts.Hatton, EFontWeight.Medium, [12, 12, 12], [16, 16, 16])}
            ${fluidSizing([
                { prop: 'font-size', values: [18, 18, 18] },
                { prop: 'line-height', values: [24, 24, 24] },
                { prop: 'margin-bottom', values: [5, 5, 8] }
            ])}
        }
    `
);

export const Wrapper = styled.div(
    ({ theme }) => css`
        display: flex;
        flex: 1;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        ${theme.breakpoints.only('xs')} {
            ${fluidSizing([
                { prop: 'padding-bottom', values: [0, 15, 15] },
                { prop: 'margin-bottom', values: [8, 8, 8] }
            ])};
        }
    `
);

export const InputContainer = styled(OxForm)<{ hide: boolean }>(
    ({ theme, hide }) => css`
        position: relative;
        transition: 400ms;
        visibility: visible;
        opacity: 1;
        line-height: 1;
        ${hide &&
        css`
            visibility: hidden;
            opacity: 0;
        `}
        input {
            ${fluidSizing([{ prop: 'padding-right', values: [30, 30, 30] }])}
            ${createTextStyle(EFonts.Montserrat, EFontWeight.Medium, [10, 10, 10], [18, 18, 18])}
      ${theme.breakpoints.up('s')} {
                margin-bottom: 1rem;
            }
        }

        div {
            text-align: left;
        }
    `
);

export const IconButton = styled.button(
    ({ theme }) => css`
        width: auto;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        ${fluidSizing([
            { prop: 'right', values: [10, 10, 10] },
            { prop: 'padding', values: [5, 5, 5] }
        ])}

        ${theme.breakpoints.up('s')} {
            width: auto;
            position: absolute;
            top: 0;
            background: ${theme.colors.basic.white};
            right: 0;
            border: 1px solid ${theme.colors.primary.main};
            height: calc(100% - 1rem);
            padding: 0 1.5rem;
            transform: none;
            ${createTextStyle(EFonts.Montserrat, EFontWeight.Medium, [10, 10, 10], [18, 18, 18])}

            ${fluidSizing([{ prop: 'border-radius', values: [15, 15, 15] }])}
        }

        span {
            display: none;

            ${theme.breakpoints.up('s')} {
                display: inline;
            }
        }

        svg {
            ${fluidSizing([{ prop: 'height', values: [10, 10, 10] }])}

            ${theme.breakpoints.up('s')} {
                vertical-align: middle;
                margin-left: 1rem;
            }
        }
        &:hover {
            svg {
                animation: ${moveLeftBack} 1.5s;
            }
        }
    `
);

export const SubscribedMessage = styled.p<{ show: boolean }>(
    ({ show }) => css`
        visibility: hidden;
        opacity: 0;
        transition: 400ms;
        position: absolute;
        bottom: 35%;
        ${createTextStyle(EFonts.Montserrat, EFontWeight.Bold, [10, 10, 10], [14, 14, 14])}
        ${show &&
        css`
            opacity: 1;
            visibility: visible;
        `}
    ${fluidSizing([
            { prop: 'padding-top', values: [10, 10, 10] },
            { prop: 'width', values: [200, 200, 200] }
        ])}
    `
);

export const Content = styled.div`
    position: relative;
    width: 100%;
`;

export const SubmitIcon = styled(OxIcon)<{ isWhite: boolean }>(
    ({ theme, isWhite }) => css`
        ${isWhite &&
        css`
            color: ${theme.colors.basic.white};
        `}
    `
);
