import React, { useState } from 'react';
import * as Styled from './OxTextAreaWysiwyg.styled';
import { Editor } from '@tinymce/tinymce-react';
import { TINY_MCE_API_KEY } from 'src/config/constants';

type TProps = {
    className?: string;
    value?: string;
    name: string;
    disabled?: boolean;
    onValueChange?: (a: string, editor: Editor) => void;
};

export const OxTextAreaWysiwyg = ({
    className,
    value,
    name,
    disabled,
    onValueChange
}: TProps): JSX.Element => {
    const [showTinyMce, setShowTinyMce] = useState(false);

    const regex = /(<([^>]+)>)/gi;

    const onClick = () => {
        setShowTinyMce(true);
    };

    return (
        <Styled.Container className={className}>
            {!showTinyMce && (
                <Styled.TextArea
                    name={name}
                    onClick={onClick}
                    disabled={disabled}
                    defaultValue={value?.replace(regex, '')}
                />
            )}
            {showTinyMce && (
                <Editor
                    onInit={() => {
                        console.log('tinyMce inited');
                    }}
                    apiKey={TINY_MCE_API_KEY}
                    textareaName={name}
                    disabled={disabled}
                    value={value}
                    onEditorChange={onValueChange}
                />
            )}
        </Styled.Container>
    );
};
